import React from 'react';
import './index.css';
import { Collapse } from '@geist-ui/react';
import stfuImage1 from './assets/STFU1.png';
import stfuImage2 from './assets/STFU2.png';
import stfuImage3 from './assets/STFU3.png';

import downBadImage from './assets/DownBad.png';
import keptImage1 from './assets/Kept - o1.png';
import keptImage2 from './assets/Kept - o2.png';
import stfu1Image1 from './assets/STFU-1.png';
import stfu1Image2 from './assets/STFU-2.png';
import stfu1Image3 from './assets/STFU-3.png';


function App() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto pt-8">
        <div className="relative">
          <h1 className="text-6xl font-black text-center mb-8">
            <span className="text-gray-900">retro</span><span className="text-yellow-600">lab</span>
          </h1>
  
        </div>

        <Collapse.Group className="px-8">
        <div>
              <Collapse title={<div className="flex justify-between items-center w-full"><div><span className="font-bold text-2xl">STFU</span><span className="text-gray-500 ml-2 mr-1">•</span><span className="text-sm font-light">Simplified meet-ups.</span></div></div>} className="p-0 m-0">
                <div className="carousel carousel-center rounded-box space-x-4">
                  <div className="carousel-item">
                    <img src={stfu1Image1} className="h-72" alt="Drink" />
                  </div> 
                  <div className="carousel-item">
                    <img src={stfu1Image2} className="h-72" alt="Drink" />
                  </div> 
                  <div className="carousel-item">
                    <img src={stfu1Image3} className="h-72" alt="Drink" />
                  </div> 
                </div>
                
              </Collapse>
            </div>
  <div>
    <Collapse title={<div className="flex justify-between items-center w-full"><div><span className="font-bold text-2xl">Kept</span><span className="text-gray-500 ml-2 mr-1">•</span><span className="text-sm font-light">A digital copilot for small business. gokept.com.</span></div></div>} className="p-0 m-0">
      <div className="carousel carousel-center rounded-box space-x-4">
                  <div className="carousel-item">
                    <img src={keptImage1} className="h-72" alt="Drink" />
                  </div> 
                  <div className="carousel-item">
                    <img src={keptImage2} className="h-72" alt="Drink" />
                  </div> 
                </div>
    </Collapse>
  </div>

  <div>
    <Collapse title={<div className="flex justify-between items-center w-full "><div><span className="font-bold text-2xl">Housewarming</span><span className="text-gray-500 ml-2 mr-1">•</span><span className="text-sm font-light">Find apartments in your network.</span></div></div>} className="p-0 m-0">
    <div className="carousel carousel-center rounded-box space-x-4">
                  <div className="carousel-item">
                    <img src={stfuImage1} className="h-72" alt="Drink" />
                  </div> 
                  <div className="carousel-item">
                    <img src={stfuImage2} className="h-72" alt="Drink" />
                  </div> 
                  <div className="carousel-item">
                    <img src={stfuImage3} className="h-72" alt="Drink" />
                  </div> 
                </div>    </Collapse>
  </div>

  <div>
    <Collapse title={<div className="flex justify-between items-center w-full"><div><span className="font-bold text-2xl">DownBad</span><span className="text-gray-500 ml-2 mr-1">•</span><span className="text-sm font-light">Indexing social media.</span></div></div>} className="p-0 m-0">
    <div className="carousel-item">
                    <img src={downBadImage} className="h-72" alt="Drink" />
                  </div> 
                </Collapse>
  </div>
</Collapse.Group>
  
        </div>
      </div>
  );
}

export default App;
